import { useRecoilState } from "recoil";
import ContinueBtn from "../btn/continueBtn/continueBtn";
import {
    VerificationFailedPopupContainer,
    VerificationFailedPopupContent,
    VerificationFailedPopupSubtitle,
    VerificationFailedPopupTitle,
    VerificationFailedPopupWrapper,
} from "./verificationFailedPopupStyling";
import { guestModeSelector, userModelSelector } from "../../state/selectors/userModel";
import { useLocation, useNavigate } from "react-router-dom";
import { stripeAccountSelector } from "../../state/selectors/stripeAccount";
import Stripe from "stripe";
import { useEffect, useState } from "react";
import { callApi } from "../../utils/api/api";
import { accessTokenSelector } from "../../state/selectors/accessToken";

const VerificationFailedPopup = () => {
    const [guestMode, setGuestMode] = useRecoilState(guestModeSelector);
    const [userModel, setUserModel] = useRecoilState(userModelSelector);
    const [stripeAccount, setStripeAccount] = useRecoilState<Stripe.Account | null>(stripeAccountSelector);
    const [accessToken, setAccessToken] = useRecoilState(accessTokenSelector);

    const [verificationFailed, setVerificationFailed] = useState(false);

    const navigate = useNavigate();

    const location = useLocation();

    const getUserStripeAccount = async (stripeAccountId: string) => {
        try {
            const res = await callApi("post", `${process.env.REACT_APP_ENV}-anystay-user`, "/user/stripe/verify", {
                headers: {
                    Authorization: accessToken,
                },
                body: {
                    stripeAccountId: stripeAccountId,
                },
            });
            if (res && res.success) {
                setStripeAccount(res.account);
                if (res.account.individual && res.account.individual.requirements.errors.length > 0) {
                    setVerificationFailed(true);
                }
            }
        } catch (error: any) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        if (!userModel || guestMode) return;
        if (!stripeAccount) return;
        getUserStripeAccount(stripeAccount.id);

        if (stripeAccount.individual && stripeAccount.individual.requirements && stripeAccount.individual.requirements.pending_verification.length > 0) {
            const interval = setInterval(() => getUserStripeAccount(stripeAccount.id), 10000);

            return () => {
                clearInterval(interval);
            };
        }
    }, []);

    if (!userModel || guestMode) return null;
    if (userModel.userIDConfirmed) return null;
    if (stripeAccount && stripeAccount.individual && stripeAccount.individual.requirements?.currently_due.length === 0 && !verificationFailed) return null;
    if (verificationFailed) {
        return (
            <VerificationFailedPopupContainer>
                <VerificationFailedPopupWrapper>
                    <VerificationFailedPopupContent>
                        <VerificationFailedPopupTitle>We couldn’t verify you</VerificationFailedPopupTitle>
                        <VerificationFailedPopupSubtitle>Verify again to start getting paid</VerificationFailedPopupSubtitle>
                    </VerificationFailedPopupContent>
                    <ContinueBtn
                        style={{ padding: "7px 12px", width: "unset", height: "36px", margin: "unset", flexShrink: 0 }}
                        background="black"
                        isLoading={false}
                        disabled={false}
                        onClick={() => {
                            if (location.pathname === "/payout-settings") {
                                navigate("/payout-settings#openPopup", { state: { redirectFrom: "verificationFailedPopup" } });
                            } else {
                                navigate("/payout-settings", { state: { redirectFrom: "verificationFailedPopup" } });
                            }
                        }}
                    >
                        Try again
                    </ContinueBtn>
                </VerificationFailedPopupWrapper>
            </VerificationFailedPopupContainer>
        );
    } else {
        return null;
    }
};

export default VerificationFailedPopup;
