import Footer from "../../components/footer/footer";
import { useRecoilState } from "recoil";
import {
    HostReferralPageContainer,
    ReferralDescription,
    ReferralDescriptionTitleWrapper,
    ReferralDescriptionNumber,
    ReferralDescriptionTitle,
    ReferralDescriptionSubTitle,
    ReferralDescriptionWrapper,
    HostReferralLogInBtn,
    HostReferralLogInBtnIcon,
    HostReferralLogInBtnText,
    HostReferralImgFrame,
    HostReferralTitle,
    HostReferralSubtitle,
    HostReferralTitleWrapper,
    HostReferralEarningsWrapper,
    HostReferralEarningsSection,
    HostReferralEarningsTitle,
    HostReferralEarningsSubtitle,
    HostReferralEarningsDescription,
    HostReferralEarningsDescriptionWrapper,
    HostReferralImageFrontIcon,
    HostReferralImageBackIcon,
} from "./referralPageStyling";
import HelmetTags from "../../components/Helmet/HelmetTags";
import { ColorTheme } from "../../components/globaStyledComponents/globalStyledComponentsStyling";
import { signInMenuActivatedSelector } from "../../state/selectors/navbarToggle";
import { useEffect, useState } from "react";
import ShareYourLink from "./popups/ShareYourLink";
import { loggedInSelector } from "../../state/selectors/loggedIn";
import { userModelSelector } from "../../state/selectors/userModel";

const HostReferralPage = () => {
    const [hover, setHover] = useState(false);
    const [signInMenuActivated, setSignInMenuActivated] = useRecoilState(signInMenuActivatedSelector);
    const [loggedIn, setLoggedIn] = useRecoilState(loggedInSelector);
    const [shareYourLink, setShareYourLink] = useState(false);
    const [userModel, setUserModel] = useRecoilState(userModelSelector);

    const handleClosePopup = () => {
        setShareYourLink((prev) => !prev);
    };

    return (
        <>
            <HelmetTags title="Host Referral | Anystay" />
            <HostReferralPageContainer>
                <HostReferralImgFrame>
                    <HostReferralImageBackIcon url="https://d292awxalydr86.cloudfront.net/Referral+page/Referral+hero.jpg" index={hover} />
                    <HostReferralImageFrontIcon url="https://d292awxalydr86.cloudfront.net/Referral+page/Referral+hero.jpg" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} />
                </HostReferralImgFrame>
                <HostReferralTitleWrapper>
                    <HostReferralTitle>Earn up to $500 AUD for every host you refer</HostReferralTitle>
                    <HostReferralSubtitle>Get paid for every eligible booking your friends receive.</HostReferralSubtitle>
                </HostReferralTitleWrapper>
                <HostReferralLogInBtn onClick={loggedIn ? () => setShareYourLink(true) : () => setSignInMenuActivated(true)}>
                    <HostReferralLogInBtnIcon width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M30 17.5V50M30 17.5H21.1607C19.8583 17.5 18.6092 16.9732 17.6883 16.0355C16.7674 15.0978 16.25 13.8261 16.25 12.5C16.25 11.1739 16.7674 9.90215 17.6883 8.96447C18.6092 8.02677 19.8583 7.5 21.1607 7.5C28.0357 7.5 30 17.5 30 17.5ZM30 17.5H38.8392C40.1417 17.5 41.3907 16.9732 42.3117 16.0355C43.2325 15.0978 43.75 13.8261 43.75 12.5C43.75 11.1739 43.2325 9.90215 42.3117 8.96447C41.3907 8.02677 40.1417 7.5 38.8392 7.5C31.9642 7.5 30 17.5 30 17.5ZM12.5 30H47.5V44.5C47.5 47.3002 47.5 48.7005 46.955 49.77C46.4757 50.7108 45.7108 51.4757 44.77 51.955C43.7005 52.5 42.3003 52.5 39.5 52.5H20.5C17.6997 52.5 16.2996 52.5 15.2301 51.955C14.2892 51.4757 13.5243 50.7108 13.045 49.77C12.5 48.7005 12.5 47.3002 12.5 44.5V30ZM11.5 30H48.5C49.9002 30 50.6003 30 51.135 29.7275C51.6055 29.4877 51.9878 29.1055 52.2275 28.635C52.5 28.1003 52.5 27.4002 52.5 26V21.5C52.5 20.0999 52.5 19.3998 52.2275 18.865C51.9878 18.3946 51.6055 18.0122 51.135 17.7725C50.6003 17.5 49.9002 17.5 48.5 17.5H11.5C10.0999 17.5 9.3998 17.5 8.86503 17.7725C8.39463 18.0122 8.01217 18.3946 7.77247 18.865C7.5 19.3998 7.5 20.0999 7.5 21.5V26C7.5 27.4002 7.5 28.1003 7.77247 28.635C8.01217 29.1055 8.39463 29.4877 8.86503 29.7275C9.3998 30 10.0999 30 11.5 30Z"
                            stroke={ColorTheme.White.color}
                            strokeWidth="5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </HostReferralLogInBtnIcon>
                    <HostReferralLogInBtnText>{loggedIn ? "Invite your friends" : "Log in to invite friends"}</HostReferralLogInBtnText>
                </HostReferralLogInBtn>
                <ReferralDescription>
                    <ReferralDescriptionWrapper>
                        <ReferralDescriptionNumber>1.</ReferralDescriptionNumber>
                        <ReferralDescriptionTitleWrapper>
                            <ReferralDescriptionTitle>Invite friends to join Anystay</ReferralDescriptionTitle>
                            <ReferralDescriptionSubTitle>Invite one or more friends to host their place on Anystay. You can invite as many as you want.</ReferralDescriptionSubTitle>
                        </ReferralDescriptionTitleWrapper>
                    </ReferralDescriptionWrapper>

                    <ReferralDescriptionWrapper>
                        <ReferralDescriptionNumber>2.</ReferralDescriptionNumber>
                        <ReferralDescriptionTitleWrapper>
                            <ReferralDescriptionTitle>Get paid when your friends earn</ReferralDescriptionTitle>
                            <ReferralDescriptionSubTitle>We’ll pay you a share of what we earn from your friend’s first 10 bookings up to $500 AUD.</ReferralDescriptionSubTitle>
                        </ReferralDescriptionTitleWrapper>
                    </ReferralDescriptionWrapper>

                    <ReferralDescriptionWrapper>
                        <ReferralDescriptionNumber>3.</ReferralDescriptionNumber>
                        <ReferralDescriptionTitleWrapper>
                            <ReferralDescriptionTitle>Connect your payout account</ReferralDescriptionTitle>
                            <ReferralDescriptionSubTitle>Add your bank account securely and we’ll automatically transfer your earnings.</ReferralDescriptionSubTitle>
                        </ReferralDescriptionTitleWrapper>
                    </ReferralDescriptionWrapper>
                </ReferralDescription>
                <HostReferralEarningsWrapper>
                    <HostReferralEarningsSection>
                        <HostReferralEarningsTitle>Your earnings:</HostReferralEarningsTitle>
                        <HostReferralEarningsSubtitle>${userModel ? userModel.totalReferralAmount : `0.00`} AUD</HostReferralEarningsSubtitle>
                    </HostReferralEarningsSection>
                    <HostReferralEarningsDescriptionWrapper>
                        <HostReferralEarningsDescription>Your earning are calculated from eligible completed bookings received by every host referred by you.</HostReferralEarningsDescription>
                    </HostReferralEarningsDescriptionWrapper>
                </HostReferralEarningsWrapper>
            </HostReferralPageContainer>
            <Footer />
            {shareYourLink && <ShareYourLink shareYourLink={shareYourLink} handleClosePopup={handleClosePopup} hostReferralCode={userModel!.referralCode} />}
        </>
    );
};

export default HostReferralPage;
