import {
    NavbarLogoHomeButtonWrapper,
    NavbarLogoHomeButton,
    NavbarLogoImage,
    NavbarLogoImageTablet,
    ColumnNavbarLogoImage,
    ColumnNavbarLogoHomeButton,
    ColumnNavbarLogoHomeButtonWrapper,
} from "./navbarStyling";
import { guestModeSelector } from "../../../state/selectors/userModel";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";
import { searchFiltersSelector } from "../../../state/selectors/searchQuery";
import { isUserChangePriceSelector } from "../../../state/selectors/isUserChangePrice";

function HomeButton({
    setNavbarMenu,
    setNavbarSearchMenuActivated,
}: {
    setNavbarMenu: React.Dispatch<React.SetStateAction<boolean>>;
    setNavbarSearchMenuActivated: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const [guestMode, setGuestMode] = useRecoilState(guestModeSelector);
    const [searchFilters, setSearchFilters] = useRecoilState(searchFiltersSelector);
    const [isUserChangePrice, setIsUserChangePrice] = useRecoilState(isUserChangePriceSelector);
    const navigate = useNavigate();

    const location = useLocation();

    if (!guestMode) {
        if (
            !location.pathname.includes("/settings") &&
            !location.pathname.includes("/edit-profile") &&
            !location.pathname.includes("/security-settings") &&
            !location.pathname.includes("/payment-settings") &&
            !location.pathname.includes("/payout-settings") &&
            !location.pathname.includes("/host/profile") &&
            !location.pathname.includes("/host-referral-page")
        ) {
            return (
                <ColumnNavbarLogoHomeButtonWrapper>
                    <ColumnNavbarLogoHomeButton
                        onClick={() => {
                            setNavbarMenu(false);
                            navigate("/dashboard");
                        }}
                    >
                        <ColumnNavbarLogoImage src={"https://anystay-assets.s3.ap-southeast-2.amazonaws.com/Branding/(new1)+logo+icon+black.svg"} alt={"Anystay Logo"} />
                    </ColumnNavbarLogoHomeButton>
                </ColumnNavbarLogoHomeButtonWrapper>
            );
        } else {
            return (
                <NavbarLogoHomeButtonWrapper>
                    <NavbarLogoHomeButton
                        onClick={() => {
                            setNavbarMenu(false);
                            navigate("/dashboard");
                        }}
                    >
                        <NavbarLogoImage location={location.pathname} src={"https://d292awxalydr86.cloudfront.net/Branding/Full+logo+2.svg"} alt={"Anystay Logo"} />
                    </NavbarLogoHomeButton>
                </NavbarLogoHomeButtonWrapper>
            );
        }
    }
    return (
        <NavbarLogoHomeButtonWrapper>
            <NavbarLogoHomeButton
                onClick={() => {
                    setNavbarSearchMenuActivated(false);
                    setNavbarMenu(false);
                    const searchFiltersCopy = JSON.parse(JSON.stringify(searchFilters));
                    searchFiltersCopy.category = "all";
                    setSearchFilters(searchFiltersCopy);
                    if (location.pathname !== "/") {
                        setIsUserChangePrice(false);
                    }
                    navigate("/");
                }}
            >
                <NavbarLogoImage location={location.pathname} src={"https://d292awxalydr86.cloudfront.net/Branding/Full+logo+2.svg"} alt={"Anystay Logo"} />
                <NavbarLogoImageTablet src={"https://d292awxalydr86.cloudfront.net/Branding/(new1)+logo+icon+red.svg"} alt={"Anystay Logo"} />
            </NavbarLogoHomeButton>
        </NavbarLogoHomeButtonWrapper>
    );
}

export default HomeButton;
