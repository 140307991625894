import {
    HostPopupWrapper,
    HostPopupTitleWrapper,
    HostPopupTitle,
    HostInputWrapper,
    HostInputTitle,
    HostInputSubtitle,
    HostInputTitleContainer,
    HostShareButtonIcon,
    HostShareButtonWrapper,
} from "../referralPageStyling";
import useClickOutside from "../../../hook/useClickOutside";
import GlobalPopupContainerCenter from "../../../components/globalPopup/GlobalPopupContainerCenter";

interface GetYourCodePopupProps {
    shareYourLink: boolean;
    handleClosePopup: () => void;
    hostReferralCode: string;
}

export default function ShareYourLink({ shareYourLink, handleClosePopup, hostReferralCode }: GetYourCodePopupProps) {
    const getCodePopupRef = useClickOutside(shareYourLink, handleClosePopup);
    const link = `www.anystay.com.au/list-your-place?ref=${hostReferralCode}`;

    const copyLink = () => {
        navigator.clipboard.writeText(link);
    };

    return (
        <GlobalPopupContainerCenter ref={getCodePopupRef} backBtnType="Close" closePopup={handleClosePopup} handleSave={handleClosePopup} btnText="Done">
            <HostPopupWrapper>
                <HostPopupTitleWrapper>
                    <HostPopupTitle>Copy and share your invite link</HostPopupTitle>
                </HostPopupTitleWrapper>
                <HostInputWrapper>
                    <HostInputTitleContainer>
                        <HostInputTitle>{link}</HostInputTitle>
                        <HostShareButtonWrapper>
                            <HostShareButtonIcon src={"https://d292awxalydr86.cloudfront.net/Universal+icons/Copy.svg"} onClick={copyLink} />
                        </HostShareButtonWrapper>
                    </HostInputTitleContainer>
                    <HostInputSubtitle>Hosts joining from your link will count towards your earnings.</HostInputSubtitle>
                </HostInputWrapper>
            </HostPopupWrapper>
        </GlobalPopupContainerCenter>
    );
}
