import { useEffect, useState } from "react";
import { Navbar, NavbarWrapper, ColumnNavbar, ColumnNavbarWrapper, ColumnNavbarHoverWrapper } from "./navbarStyling";
import { useRecoilState } from "recoil";
import { useLocation } from "react-router-dom";

// Import navbar components
import HomeButton from "./navbarHomeButton";
import HostNavbarDesktopMenu from "./hostNavbarMenu";
import MiniDesktopSearchbar from "./search/MiniDesktopSearchbar";
import NavbarSearchbarPopDownInput from "./search/NavbarSearchbarPopDownInput";
import LocationBasedComponents from "./Components/LocationBasedComponents";
import ColumnNavbarProfileComponents from "./Components/ColumnNavbarProfileComponents";
// import NotificationsTray from "../notifications/notifications";
import SaveAndExitPopup from "../saveAndExitPopup/saveAndExitPopup";
import { useNavigate } from "react-router-dom";

// Import atom selectors
import { userModelSelector, guestModeSelector } from "../../../state/selectors/userModel";
import { mobileSearchMenuActivatedSelector, navbarSearchMenuActivatedSelector } from "../../../state/selectors/navbarToggle";
import useIsDesktopSize from "../../../hook/useIsDesktopSize";
import { AnimatePresence } from "framer-motion";
import { userHostModelSelector } from "../../../state/selectors/userHostModel";

interface NavbarComponentProps {
    showFullPageLoading: React.Dispatch<React.SetStateAction<boolean>>;
    loggingIn: boolean;
}

export default function NavbarComponent({ showFullPageLoading, loggingIn }: NavbarComponentProps) {
    const location = useLocation();
    const navigate = useNavigate();

    const [userModel, setUserModel] = useRecoilState(userModelSelector);
    const [guestMode, setGuestMode] = useRecoilState(guestModeSelector);
    const [mobileSearchMenuActivated, setMobileSearchMenuActivated] = useRecoilState(mobileSearchMenuActivatedSelector);
    const [navbarSearchMenuActivated, setNavbarSearchMenuActivated] = useRecoilState(navbarSearchMenuActivatedSelector);
    const [userHostModel, setUserHostModel] = useRecoilState(userHostModelSelector);

    const [navbarMenu, setNavbarMenu] = useState(false);
    const [saveAndExitPopup, setSaveAndExitPopup] = useState(false);
    //notification not in use for now
    const [notificationsPopup, setNotificationsPopup] = useState(false);
    const isDesktopSize = useIsDesktopSize();

    const [columnNavText, setColumnNavText] = useState(false);
    //mobile searchanywhere popdown and desktop search popdown
    const navbarpopdownActive = () => {
        if (!isDesktopSize && !navbarMenu && !mobileSearchMenuActivated) {
            return location.pathname === "/" || location.pathname.includes("/search");
        } else if (isDesktopSize && navbarSearchMenuActivated && !navbarMenu) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (mobileSearchMenuActivated) {
            document.body.style.overflow = "hidden";
            document.body.style.position = "relative";
            document.body.style.height = "-webkit-fill-available";
            document.documentElement.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
            document.body.style.position = "unset";
            document.body.style.height = "unset";
            document.documentElement.style.overflow = "unset";
        }
    }, [mobileSearchMenuActivated, navbarMenu]);

    return (
        <>
            {userModel &&
            !guestMode &&
            !location.pathname.includes("/settings") &&
            !location.pathname.includes("/edit-profile") &&
            !location.pathname.includes("/security-settings") &&
            !location.pathname.includes("/payment-settings") &&
            !location.pathname.includes("/payout-settings") &&
            !location.pathname.includes("/host/profile") &&
            !location.pathname.includes("/host-referral-page") ? (
                <ColumnNavbarWrapper
                    location={location.pathname}
                    navbarMenu={navbarMenu}
                    onMouseEnter={() => {
                        setColumnNavText(true);
                    }}
                    onMouseLeave={() => {
                        setColumnNavText(false);
                    }}
                >
                    <ColumnNavbarHoverWrapper navbarMenu={navbarMenu}>
                        <ColumnNavbar location={location.pathname}>
                            <HomeButton setNavbarMenu={setNavbarMenu} setNavbarSearchMenuActivated={setNavbarSearchMenuActivated} />
                            <HostNavbarDesktopMenu columnNavText={columnNavText} navbarMenu={navbarMenu} />

                            <ColumnNavbarProfileComponents
                                navbarMenu={navbarMenu}
                                setNavbarMenu={setNavbarMenu}
                                setNavbarSearchMenuActivated={setNavbarSearchMenuActivated}
                                columnNavText={columnNavText}
                                setColumnNavText={setColumnNavText}
                            />
                        </ColumnNavbar>
                    </ColumnNavbarHoverWrapper>
                </ColumnNavbarWrapper>
            ) : (
                <NavbarWrapper location={location.pathname} navbarMenu={navbarMenu} id="navbar-wrapper">
                    <Navbar location={location.pathname} navbarMenu={navbarMenu} id="navbar">
                        <HomeButton setNavbarMenu={setNavbarMenu} setNavbarSearchMenuActivated={setNavbarSearchMenuActivated} />
                        <MiniDesktopSearchbar setNavbarSearchMenuActivated={setNavbarSearchMenuActivated} navbarpopdownActive={navbarpopdownActive()} setNavbarMenu={setNavbarMenu} />
                        <LocationBasedComponents
                            navbarMenu={navbarMenu}
                            setNavbarMenu={setNavbarMenu}
                            setNotificationsPopup={setNotificationsPopup}
                            notificationsPopup={notificationsPopup}
                            setNavbarSearchMenuActivated={setNavbarSearchMenuActivated}
                        />
                    </Navbar>

                    {/* <Navbar location={location.pathname} navbarMenu={navbarMenu}>
        <NavbarWrapper location={location.pathname} navbarMenu={navbarMenu} id="navbar-wrapper">
            <Navbar location={location.pathname} navbarMenu={navbarMenu} id="navbar">
                <HomeButton setNavbarMenu={setNavbarMenu} setNavbarSearchMenuActivated={setNavbarSearchMenuActivated} />

                {userModel && !guestMode ? (
                    <HostNavbarDesktopMenu />
                ) : (
                    <MiniDesktopSearchbar setNavbarSearchMenuActivated={setNavbarSearchMenuActivated} navbarpopdownActive={navbarpopdownActive()} setNavbarMenu={setNavbarMenu} />
                )}

                <LocationBasedComponents
                    navbarMenu={navbarMenu}
                    setNavbarMenu={setNavbarMenu}
                    setNotificationsPopup={setNotificationsPopup}
                    notificationsPopup={notificationsPopup}
                    setSaveAndExitPopup={setSaveAndExitPopup}
                    loggingIn={loggingIn}
                    setNavbarSearchMenuActivated={setNavbarSearchMenuActivated}
                />
            </Navbar> */}

                    {saveAndExitPopup && (
                        <SaveAndExitPopup
                            closeSaveAndExitPopup={() => {
                                setSaveAndExitPopup(false);
                                navigate(`/your-listings/${userHostModel?.id}`);
                            }}
                            activated={saveAndExitPopup}
                        />
                    )}
                    <AnimatePresence>
                        {navbarpopdownActive() && (
                            <NavbarSearchbarPopDownInput
                                setNavbarSearchMenuActivated={setNavbarSearchMenuActivated}
                                setMobileSearchMenuActivated={setMobileSearchMenuActivated}
                                active={navbarpopdownActive()}
                            />
                        )}
                    </AnimatePresence>
                </NavbarWrapper>
            )}
        </>
    );
}
