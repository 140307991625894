import styled from "styled-components";
import { BorderRadiusTheme, ColorTheme, ShadowTheme, TextTheme } from "../globaStyledComponents/globalStyledComponentsStyling";

export const VerificationFailedPopupContainer = styled.div`
    width: 100%;
    position: fixed;
    bottom: 24px;
    display: flex;
    justify-content: center;
    z-index: 101;
    pointer-events: none;
`;

export const VerificationFailedPopupWrapper = styled.div`
    margin: 0 auto;
    padding: 16px 20px;
    display: flex;
    align-items: center;
    gap: 32px;
    border-radius: ${BorderRadiusTheme.radius_12.borderRadius};
    box-shadow: ${ShadowTheme.shadow_100.boxShadow};
    background-color: ${ColorTheme.White.color};
    pointer-events: auto;
`;

export const VerificationFailedPopupContent = styled.div`
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const VerificationFailedPopupTitle = styled.h1`
    font-size: ${TextTheme.sans_400_semi.fontSize};
    line-height: ${TextTheme.sans_400_semi.lineHeight};
    font-weight: ${TextTheme.sans_400_semi.fontWeight};
`;

export const VerificationFailedPopupSubtitle = styled.p`
    font-size: ${TextTheme.sans_300.fontSize};
    line-height: ${TextTheme.sans_300.lineHeight};
    font-weight: ${TextTheme.sans_300.fontWeight};
    color: ${ColorTheme.Grey.color};
`;
