import {
    NavbarMenuWrapper,
    NavbarMenuBtn,
    NavbarMenuItem,
    NavbarMenuDivider,
    NavbarMenuItemMainIcon,
    NavbarMenuItemMainItemOutsideLink,
    NavbarMenuRedDot,
    NavbarMenuTextWrapper,
    NavbarMenuTextItemWrapper,
    NavbarMenuText1,
    NavbarMenuText2,
} from "./navbarStyling";
import { userModelSelector, guestModeSelector } from "../../../state/selectors/userModel";
import { userHostModelSelector, userHostConversationsModelSelector } from "../../../state/selectors/userHostModel";
import { signInMenuActivatedSelector } from "../../../state/selectors/navbarToggle";
import { useRecoilState, useRecoilValue } from "recoil";
import { logoutUser } from "../../../utils/auth/logoutUser";
import useClickOutside from "../../../hook/useClickOutside";
import { searchFiltersSelector } from "../../../state/selectors/searchQuery";
import { isUserChangePriceSelector } from "../../../state/selectors/isUserChangePrice";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ColorTheme } from "../../globaStyledComponents/globalStyledComponentsStyling";
interface NavbarDropDownMenuProps {
    closeNavbarMenu: () => void;
    navbarMenu: boolean;
    setNavbarMenu: React.Dispatch<React.SetStateAction<boolean>>;
    isColumn?: boolean;
    setColumnNavText?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function NavbarDropDownMenu({ closeNavbarMenu, navbarMenu, setNavbarMenu, isColumn, setColumnNavText }: NavbarDropDownMenuProps) {
    const [userModel, setUserModel] = useRecoilState(userModelSelector);
    const [userHostModel, setUserHostModel] = useRecoilState(userHostModelSelector);
    const userHostConversations = useRecoilValue(userHostConversationsModelSelector);
    const [guestMode, setGuestMode] = useRecoilState(guestModeSelector);
    const [signInMenuActivated, setSignInMenuActivated] = useRecoilState(signInMenuActivatedSelector);

    const [searchFilters, setSearchFilters] = useRecoilState(searchFiltersSelector);
    const [isUserChangePrice, setIsUserChangePrice] = useRecoilState(isUserChangePriceSelector);

    const location = useLocation();
    const navigate = useNavigate();

    const wrapperRef = useClickOutside(
        navbarMenu,
        () => {
            setNavbarMenu(false);
        },
        true
    );
    const renderSwitchOrBecomeHostBtn = () => {
        if (userHostModel) {
            const renderSwitchToHost = userHostModel.listingModelNumber && userHostModel.listingModelNumber > 0;
            return (
                <NavbarMenuItem
                    to={renderSwitchToHost ? "/dashboard" : "/list-your-place"}
                    onClick={async () => {
                        closeNavbarMenu();
                    }}
                    style={{ padding: "13px 20px" }}
                >
                    {renderSwitchToHost ? "Switch to hosting" : "Become a host"}
                </NavbarMenuItem>
            );
        }
    };

    if (!userModel) {
        return (
            <NavbarMenuWrapper ref={wrapperRef}>
                {window.innerWidth < 600 && <NavbarMenuDivider style={{ margin: 0 }} />}
                <NavbarMenuBtn
                    onClick={() => {
                        setSignInMenuActivated(true);
                        closeNavbarMenu();
                    }}
                >
                    Sign up
                </NavbarMenuBtn>
                <NavbarMenuBtn
                    onClick={() => {
                        setSignInMenuActivated(true);
                        closeNavbarMenu();
                    }}
                >
                    Log in
                </NavbarMenuBtn>
                <NavbarMenuDivider />
                <NavbarMenuItem
                    to={"/list-your-place"}
                    onClick={() => {
                        closeNavbarMenu();
                    }}
                    style={{ padding: "13px 20px" }}
                >
                    Become a host
                </NavbarMenuItem>
                <NavbarMenuItemMainItemOutsideLink target={window.innerWidth > 768 ? "_blank" : ""} href="https://help.anystay.com.au" style={{ gap: "12px" }}>
                    Help center
                </NavbarMenuItemMainItemOutsideLink>
                <NavbarMenuDivider />
                <NavbarMenuTextItemWrapper
                    to="/referral-page"
                    onClick={() => {
                        closeNavbarMenu();
                    }}
                >
                    <NavbarMenuTextWrapper>
                        <NavbarMenuText1>Refer a friend</NavbarMenuText1>
                        <NavbarMenuText2>Invite friends and earn</NavbarMenuText2>
                    </NavbarMenuTextWrapper>
                    <NavbarMenuRedDot />
                </NavbarMenuTextItemWrapper>
            </NavbarMenuWrapper>
        );
    } else if (guestMode) {
        return (
            <NavbarMenuWrapper ref={wrapperRef}>
                {window.innerWidth < 600 && <NavbarMenuDivider style={{ margin: 0 }} />}
                <NavbarMenuItem
                    to={
                        window.innerWidth < 768
                            ? `/your-inbox/${userModel.id}`
                            : userModel.ConversationModels.items.length > 0
                              ? `/conversation/${userModel.ConversationModels.items.at(-1)!.id}`
                              : "/conversation/null"
                    }
                    onClick={() => {
                        closeNavbarMenu();
                    }}
                    style={{ padding: "12px 20px" }}
                >
                    <NavbarMenuItemMainIcon width="24" height="24" viewBox="0 0 24 24" fill="none" aria-label={"Messages"}>
                        <mask id="path-mask" fill="white">
                            <path d="M0 6.24062C0 4.0562 0 2.96398 0.436 2.12965C0.819 1.39574 1.431 0.79906 2.184 0.425117C3.04 0 4.16 0 6.4 0H17.6C19.84 0 20.96 0 21.816 0.425117C22.569 0.79906 23.181 1.39574 23.564 2.12965C24 2.96398 24 4.0562 24 6.24062V13.2613C24 15.4458 24 16.5379 23.564 17.3723C23.181 18.1063 22.569 18.7029 21.816 19.0768C20.96 19.5019 19.84 19.5019 17.6 19.5019H14.245C13.413 19.5019 12.997 19.5019 12.599 19.5816C12.246 19.6522 11.904 19.7691 11.583 19.929C11.222 20.1092 10.897 20.3626 10.247 20.8694L7.066 23.3506C6.512 23.7834 6.234 23.9997 6.001 24C5.798 24 5.606 23.9102 5.479 23.7554C5.333 23.5776 5.333 23.2312 5.333 22.5384V19.5019C4.093 19.5019 3.473 19.5019 2.965 19.3691C1.584 19.0084 0.506 17.957 0.136 16.6111C0 16.1151 0 15.5105 0 14.3014V6.24062Z" />
                        </mask>
                        <path
                            d="M0 6.24062C0 4.0562 0 2.96398 0.436 2.12965C0.819 1.39574 1.431 0.79906 2.184 0.425117C3.04 0 4.16 0 6.4 0H17.6C19.84 0 20.96 0 21.816 0.425117C22.569 0.79906 23.181 1.39574 23.564 2.12965C24 2.96398 24 4.0562 24 6.24062V13.2613C24 15.4458 24 16.5379 23.564 17.3723C23.181 18.1063 22.569 18.7029 21.816 19.0768C20.96 19.5019 19.84 19.5019 17.6 19.5019H14.245C13.413 19.5019 12.997 19.5019 12.599 19.5816C12.246 19.6522 11.904 19.7691 11.583 19.929C11.222 20.1092 10.897 20.3626 10.247 20.8694L7.066 23.3506C6.512 23.7834 6.234 23.9997 6.001 24C5.798 24 5.606 23.9102 5.479 23.7554C5.333 23.5776 5.333 23.2312 5.333 22.5384V19.5019C4.093 19.5019 3.473 19.5019 2.965 19.3691C1.584 19.0084 0.506 17.957 0.136 16.6111C0 16.1151 0 15.5105 0 14.3014V6.24062Z"
                            stroke={ColorTheme.Black.color}
                            strokeWidth="3.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            mask="url(#path-mask)"
                        />
                    </NavbarMenuItemMainIcon>
                    Messages
                </NavbarMenuItem>
                <NavbarMenuItem
                    to={`/your-stays/${userModel?.id}`}
                    onClick={() => {
                        closeNavbarMenu();
                    }}
                    style={{ padding: "12px 20px" }}
                >
                    <NavbarMenuItemMainIcon width="24" height="24" viewBox="0 0 24 24" fill="none" aria-label={"Trips icon"}>
                        <path
                            d="M23.3055 0L24 1.43547L22.099 2.41814V22.3943C22.099 23.2405 21.4651 23.9342 20.6611 23.9952L20.5453 24H3.45465C2.63585 24 1.96465 23.3449 1.90561 22.514L1.90095 22.3943V12.855L0.694503 13.478L0 12.0426L1.90095 11.0607V4.73192H3.45465V10.2578L23.3055 0ZM20.5453 3.22098L3.45465 12.0522V22.3943H8.11575V14.366C8.11575 13.5198 8.74966 12.8261 9.5537 12.7643L9.66945 12.7603H14.3305C15.1493 12.7603 15.8205 13.4154 15.8804 14.2463L15.8842 14.366V22.3943H20.5453V3.22098ZM14.3305 14.366H9.66945V22.3943H14.3305V14.366Z"
                            fill={ColorTheme.Black.color}
                        />
                    </NavbarMenuItemMainIcon>
                    Trips
                </NavbarMenuItem>
                <NavbarMenuItem
                    to={`/saved-listings/${userModel?.id}`}
                    onClick={() => {
                        closeNavbarMenu();
                    }}
                    style={{ padding: "12px 20px" }}
                >
                    <NavbarMenuItemMainIcon width="24" height="24" viewBox="0 0 24 24" fill="none" aria-label={"Saved icon"}>
                        <g filter="url(#filter0_d_49605_13976)">
                            <mask id="path-1-inside-1_49605_13976" fill="white">
                                <path d="M12.2908 4.90746C12.6603 4.52202 13.0051 4.1168 13.3968 3.761C15.5124 1.83748 18.3557 1.45574 20.564 2.7838C22.8389 4.15263 23.9832 7.03113 23.306 9.75643C22.6919 12.231 21.1979 14.1841 19.5111 16.0088C17.4487 18.24 15.0663 20.1017 12.6158 21.8795C12.4626 21.9907 12.1191 22.0463 11.9918 21.9536C8.62569 19.5026 5.34855 16.9465 2.93766 13.4787C1.85764 11.927 1.0396 10.2518 1.00129 8.30607C0.950625 5.74136 2.39765 3.39409 4.57623 2.45394C6.76222 1.51009 9.40295 2.08826 11.2714 3.91914C11.5914 4.23293 11.9152 4.54302 12.2908 4.90746Z" />
                            </mask>
                            <path
                                d="M12.2908 4.90746C12.6603 4.52202 13.0051 4.1168 13.3968 3.761C15.5124 1.83748 18.3557 1.45574 20.564 2.7838C22.8389 4.15263 23.9832 7.03113 23.306 9.75643C22.6919 12.231 21.1979 14.1841 19.5111 16.0088C17.4487 18.24 15.0663 20.1017 12.6158 21.8795C12.4626 21.9907 12.1191 22.0463 11.9918 21.9536C8.62569 19.5026 5.34855 16.9465 2.93766 13.4787C1.85764 11.927 1.0396 10.2518 1.00129 8.30607C0.950625 5.74136 2.39765 3.39409 4.57623 2.45394C6.76222 1.51009 9.40295 2.08826 11.2714 3.91914C11.5914 4.23293 11.9152 4.54302 12.2908 4.90746Z"
                                stroke={ColorTheme.Black.color}
                                strokeWidth="3.2"
                                mask="url(#path-1-inside-1_49605_13976)"
                            />
                        </g>
                        <defs>
                            <filter id="filter0_d_49605_13976" x="-13.7" y="-11" width="54.5" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dx="1.3" dy="3" />
                                <feGaussianBlur stdDeviation="8" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_49605_13976" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_49605_13976" result="shape" />
                            </filter>
                        </defs>
                    </NavbarMenuItemMainIcon>
                    Wishlists
                </NavbarMenuItem>
                <NavbarMenuDivider />
                {renderSwitchOrBecomeHostBtn()}
                <NavbarMenuItemMainItemOutsideLink target={window.innerWidth > 768 ? "_blank" : ""} href="https://help.anystay.com.au" style={{ gap: "12px" }}>
                    Help center
                </NavbarMenuItemMainItemOutsideLink>
                <NavbarMenuItem
                    to="/settings"
                    onClick={() => {
                        closeNavbarMenu();
                    }}
                >
                    Profile
                </NavbarMenuItem>
                <NavbarMenuDivider />
                <NavbarMenuTextItemWrapper
                    to="/referral-page"
                    onClick={() => {
                        closeNavbarMenu();
                    }}
                >
                    <NavbarMenuTextWrapper>
                        <NavbarMenuText1>Refer a friend</NavbarMenuText1>
                        <NavbarMenuText2>Invite friends and earn</NavbarMenuText2>
                    </NavbarMenuTextWrapper>
                    <NavbarMenuRedDot />
                </NavbarMenuTextItemWrapper>
                <NavbarMenuItem
                    to="/"
                    onClick={async () => {
                        await logoutUser();
                        closeNavbarMenu();
                        navigate("/");
                    }}
                >
                    Log out
                </NavbarMenuItem>
            </NavbarMenuWrapper>
        );
    } else if (!guestMode) {
        return (
            <NavbarMenuWrapper ref={wrapperRef} isColumn={isColumn}>
                {(location.pathname.includes("/settings") ||
                    location.pathname.includes("/security-settings") ||
                    location.pathname.includes("/edit-profile") ||
                    location.pathname.includes("/payment-settings") ||
                    location.pathname.includes("/payout-settings") ||
                    location.pathname.includes("/host/profile") ||
                    location.pathname.includes("/host-referral-page")) && (
                    <>
                        <NavbarMenuItem
                            to={
                                window.innerWidth < 768
                                    ? `/your-host-inbox/${userModel?.id}`
                                    : userHostConversations && userHostConversations.length > 0
                                      ? `/host-conversation/${userHostConversations.at(-1)?.id}`
                                      : "/host-conversation/null"
                            }
                            onClick={() => {
                                closeNavbarMenu();
                            }}
                            style={{ padding: "12px 20px" }}
                        >
                            <NavbarMenuItemMainIcon width="24" height="24" viewBox="0 0 24 24" fill="none" aria-label="Messages icon">
                                <mask id="path-1-inside-1_54966_12034" fill="white">
                                    <path d="M0 6.24062C0 4.0562 1.49012e-07 2.96398 0.435974 2.12965C0.819467 1.39574 1.43139 0.79906 2.18404 0.425117C3.03968 1.49012e-07 4.15979 0 6.4 0H17.6C19.8403 0 20.9603 1.49012e-07 21.816 0.425117C22.5687 0.79906 23.1805 1.39574 23.564 2.12965C24 2.96398 24 4.0562 24 6.24062V13.2613C24 15.4458 24 16.5379 23.564 17.3723C23.1805 18.1063 22.5687 18.7029 21.816 19.0768C20.9603 19.5019 19.8403 19.5019 17.6 19.5019H14.2449C13.4129 19.5019 12.9969 19.5019 12.5989 19.5816C12.2459 19.6522 11.9043 19.7691 11.5833 19.929C11.2216 20.1092 10.8967 20.3626 10.2469 20.8694L7.06635 23.3506C6.51156 23.7834 6.23417 23.9997 6.00072 24C5.79769 24.0003 5.60563 23.9102 5.47897 23.7554C5.33333 23.5776 5.33333 23.2312 5.33333 22.5384V19.5019C4.09337 19.5019 3.47339 19.5019 2.96472 19.3691C1.58436 19.0084 0.50616 17.957 0.136293 16.6111C8.9407e-08 16.1151 0 15.5105 0 14.3014V6.24062Z" />
                                </mask>
                                <path
                                    d="M0 6.24062C0 4.0562 1.49012e-07 2.96398 0.435974 2.12965C0.819467 1.39574 1.43139 0.79906 2.18404 0.425117C3.03968 1.49012e-07 4.15979 0 6.4 0H17.6C19.8403 0 20.9603 1.49012e-07 21.816 0.425117C22.5687 0.79906 23.1805 1.39574 23.564 2.12965C24 2.96398 24 4.0562 24 6.24062V13.2613C24 15.4458 24 16.5379 23.564 17.3723C23.1805 18.1063 22.5687 18.7029 21.816 19.0768C20.9603 19.5019 19.8403 19.5019 17.6 19.5019H14.2449C13.4129 19.5019 12.9969 19.5019 12.5989 19.5816C12.2459 19.6522 11.9043 19.7691 11.5833 19.929C11.2216 20.1092 10.8967 20.3626 10.2469 20.8694L7.06635 23.3506C6.51156 23.7834 6.23417 23.9997 6.00072 24C5.79769 24.0003 5.60563 23.9102 5.47897 23.7554C5.33333 23.5776 5.33333 23.2312 5.33333 22.5384V19.5019C4.09337 19.5019 3.47339 19.5019 2.96472 19.3691C1.58436 19.0084 0.50616 17.957 0.136293 16.6111C8.9407e-08 16.1151 0 15.5105 0 14.3014V6.24062Z"
                                    stroke={ColorTheme.Black.color}
                                    strokeWidth="3.2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    mask="url(#path-1-inside-1_54966_12034)"
                                />
                            </NavbarMenuItemMainIcon>
                            Inbox
                        </NavbarMenuItem>
                        <NavbarMenuItem
                            to={`/your-calendar`}
                            onClick={() => {
                                closeNavbarMenu();
                            }}
                            style={{ padding: "12px 20px" }}
                        >
                            <NavbarMenuItemMainIcon viewBox="-1 -1 32 32" height="32" width="32" stroke={ColorTheme.Black.color} fill="none" aria-label="calendar">
                                <g id="calendar-mark--calendar-date-day-month-mark">
                                    <path
                                        id="Vector"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M3.214 4.286a2.143 2.143 0 0 0 -2.143 2.143v20.357a2.143 2.143 0 0 0 2.143 2.143h23.571a2.143 2.143 0 0 0 2.143 -2.143V6.429a2.143 2.143 0 0 0 -2.143 -2.143h-4.286"
                                        strokeWidth="2"
                                    />
                                    <path id="Vector_2" strokeLinecap="round" strokeLinejoin="round" d="M7.5 1.071v6.429" strokeWidth="2" />
                                    <path id="Vector_3" strokeLinecap="round" strokeLinejoin="round" d="M22.5 1.071v6.429" strokeWidth="2" />
                                    <path id="Vector_4" strokeLinecap="round" strokeLinejoin="round" d="M7.5 4.286h10.714" strokeWidth="2" />
                                    <g id="Group 2567">
                                        <path id="Vector_5" strokeLinecap="round" strokeLinejoin="round" d="M7.5 14.464a0.536 0.536 0 0 1 0 -1.071" strokeWidth="2" />
                                        <path id="Vector_6" strokeLinecap="round" strokeLinejoin="round" d="M7.5 14.464a0.536 0.536 0 0 0 0 -1.071" strokeWidth="2" />
                                    </g>
                                    <g id="Group 2617">
                                        <path id="Vector_7" strokeLinecap="round" strokeLinejoin="round" d="M15 14.464a0.536 0.536 0 0 1 0 -1.071" strokeWidth="2" />
                                        <path id="Vector_8" strokeLinecap="round" strokeLinejoin="round" d="M15 14.464a0.536 0.536 0 0 0 0 -1.071" strokeWidth="2" />
                                    </g>
                                    <g id="Group 2619">
                                        <path id="Vector_9" strokeLinecap="round" strokeLinejoin="round" d="M7.5 21.964a0.536 0.536 0 1 1 0 -1.071" strokeWidth="2" />
                                        <path id="Vector_10" strokeLinecap="round" strokeLinejoin="round" d="M7.5 21.964a0.536 0.536 0 1 0 0 -1.071" strokeWidth="2" />
                                    </g>
                                    <g id="Group 2620">
                                        <path id="Vector_11" strokeLinecap="round" strokeLinejoin="round" d="M15 21.964a0.536 0.536 0 1 1 0 -1.071" strokeWidth="2" />
                                        <path id="Vector_12" strokeLinecap="round" strokeLinejoin="round" d="M15 21.964a0.536 0.536 0 1 0 0 -1.071" strokeWidth="2" />
                                    </g>
                                    <g id="Group 2618">
                                        <path id="Vector_13" strokeLinecap="round" strokeLinejoin="round" d="M22.5 14.464a0.536 0.536 0 1 1 0 -1.071" strokeWidth="2" />
                                        <path id="Vector_14" strokeLinecap="round" strokeLinejoin="round" d="M22.5 14.464a0.536 0.536 0 1 0 0 -1.071" strokeWidth="2" />
                                    </g>
                                </g>
                            </NavbarMenuItemMainIcon>
                            Calendar
                        </NavbarMenuItem>
                        <NavbarMenuItem
                            to={`/your-listings/${userHostModel?.id}`}
                            onClick={() => {
                                closeNavbarMenu();
                            }}
                            style={{ padding: "12px 20px" }}
                        >
                            <NavbarMenuItemMainIcon viewBox="0 0 400.000000 400.000000" fill={ColorTheme.Black.color} aria-label="Listings">
                                <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)">
                                    <path d="M105 3871 c-49 -12 -71 -28 -89 -65 -15 -31 -16 -170 -16 -1556 0 -1679 -4 -1565 63 -1605 31 -20 62 -20 1687 -20 1625 0 1656 0 1687 20 67 40 63 -74 63 1605 0 1686 5 1570 -64 1608 -29 16 -146 17 -1666 19 -899 0 -1648 -2 -1665 -6z m3145 -1621 l0 -1370 -1500 0 -1500 0 0 1370 0 1370 1500 0 1500 0 0 -1370z" />
                                    <path d="M1639 3068 c-228 -184 -736 -614 -746 -632 -7 -11 -12 -38 -13 -59 -1 -112 104 -160 203 -93 l35 24 4 -367 3 -367 30 -59 c42 -83 101 -123 197 -137 42 -5 233 -8 453 -6 l380 3 54 30 c29 16 64 43 77 60 53 70 54 76 54 475 0 203 2 370 5 370 2 0 21 -11 41 -25 51 -34 94 -41 138 -20 69 33 89 126 40 184 -23 27 -671 573 -765 644 -27 20 -47 27 -80 27 -39 0 -53 -7 -110 -52z m301 -388 l180 -151 0 -449 0 -450 -370 0 -370 0 0 450 0 449 178 150 c97 82 183 150 190 150 7 1 94 -67 192 -149z" />
                                    <path d="M3823 3145 c-18 -8 -42 -29 -53 -47 -20 -33 -20 -47 -20 -1326 0 -1129 -2 -1298 -15 -1326 -31 -66 81 -61 -1475 -66 -1309 -5 -1417 -6 -1446 -22 -33 -18 -64 -71 -64 -108 0 -37 29 -85 63 -105 31 -20 63 -20 1397 -23 857 -2 1396 1 1447 7 124 15 220 72 281 168 65 101 62 35 62 1468 0 1286 0 1300 -20 1333 -20 33 -72 62 -107 62 -10 -1 -33 -7 -50 -15z" />
                                </g>
                            </NavbarMenuItemMainIcon>
                            Listings
                        </NavbarMenuItem>
                        <NavbarMenuItem
                            to={`/payouts`}
                            onClick={() => {
                                closeNavbarMenu();
                            }}
                            style={{ padding: "12px 20px" }}
                        >
                            <NavbarMenuItemMainIcon viewBox="0 0 24 24" stroke={ColorTheme.Black.color} fill="none" aria-label="Messages icon">
                                <path
                                    d="M8 16V11M12 16V8M16 16V14M18 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V6C20 5.46957 19.7893 4.96086 19.4142 4.58579C19.0391 4.21071 18.5304 4 18 4Z"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </NavbarMenuItemMainIcon>
                            Payouts
                        </NavbarMenuItem>
                        <NavbarMenuDivider />
                    </>
                )}
                <NavbarMenuItem
                    to="/settings"
                    onClick={() => {
                        closeNavbarMenu();
                        setColumnNavText?.(false);
                    }}
                >
                    Settings
                </NavbarMenuItem>
                <NavbarMenuItemMainItemOutsideLink
                    onClick={async () => {
                        const searchFiltersCopy = JSON.parse(JSON.stringify(searchFilters));
                        searchFiltersCopy.category = "all";
                        setSearchFilters(searchFiltersCopy);
                        setIsUserChangePrice(false);
                        closeNavbarMenu();
                        setColumnNavText?.(false);
                    }}
                    target={window.innerWidth > 768 ? "_blank" : ""}
                    href="https://help.anystay.com.au"
                    style={{ gap: "12px" }}
                >
                    Help center
                </NavbarMenuItemMainItemOutsideLink>
                <NavbarMenuItem
                    to={`/create-listing/${userHostModel?.id}`}
                    onClick={() => {
                        closeNavbarMenu();
                        setColumnNavText?.(false);
                    }}
                >
                    Create a listing
                </NavbarMenuItem>
                <NavbarMenuDivider style={{ marginBottom: "8px" }} />

                <NavbarMenuTextItemWrapper
                    to="/host-referral-page"
                    onClick={() => {
                        closeNavbarMenu();
                        setColumnNavText?.(false);
                    }}
                >
                    <NavbarMenuTextWrapper>
                        <NavbarMenuText1>Refer a friend</NavbarMenuText1>
                        <NavbarMenuText2>Invite friends and earn</NavbarMenuText2>
                    </NavbarMenuTextWrapper>
                    <NavbarMenuRedDot />
                </NavbarMenuTextItemWrapper>
                <NavbarMenuItem
                    to="/"
                    onClick={async () => {
                        await logoutUser();
                        closeNavbarMenu();
                        setColumnNavText?.(false);
                        navigate("/");
                    }}
                    style={{ padding: "13px 20px" }}
                >
                    Log out
                </NavbarMenuItem>
            </NavbarMenuWrapper>
        );
    } else {
        return <></>;
    }
}
